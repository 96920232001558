/* storybook-check-ignore */
import { createContext, ProviderProps, useContext, useMemo } from 'react';

import { ListingType } from 'declarations/exclusives/exclusiveListingInfo';
import { ListingWithComputedProperties } from 'declarations/exclusives/listing';

export type ListingContextProps = {
  is1P: boolean;
  is3P: boolean;
  isOOMLS: boolean;
  listing: ListingWithComputedProperties;
};

export const ListingContext = createContext<ListingContextProps | undefined>(undefined);

export type ListingContextProviderProps = Pick<ProviderProps<ListingContextProps>, 'children'> & {
  listing: ListingWithComputedProperties;
};

export const ListingContextProvider = ({ children, listing }: ListingContextProviderProps) => {
  const values = useMemo(() => {
    return {
      is1P: listing.exclusive_listing_info.listing_type === ListingType.OD_OWNED_EXCLUSIVE,
      is3P: listing.exclusive_listing_info.listing_type === ListingType.SELLER_OWNED_EXCLUSIVE,
      isOOMLS: listing.exclusive_listing_info.listing_type === ListingType.OOMLS,
      listing,
    };
  }, [listing]);

  return <ListingContext.Provider value={values}>{children}</ListingContext.Provider>;
};

export const useListingContext = () => {
  const context = useContext(ListingContext);

  if (!context) {
    throw new Error('useListingContext must be used within a ListingContextProvider');
  }

  return context;
};
