/* storybook-check-ignore */
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';

import { BoxProps, Flex, Text } from '@opendoor/bricks/core';
import { AnimatePresence, motion, Transition, Variants } from 'framer-motion';

import CheckmarkIcon from './icons/CheckmarkIcon';
import CloseIcon from './icons/CloseIcon';
import InfoIcon from './icons/InfoIcon';

const MotionFlex = motion(Flex);

const variants: Variants = {
  visible: {
    scale: 1,
    opacity: 1,
  },
  hidden: {
    scale: 0,
    opacity: 0,
  },
};

const transitions: Transition = {
  default: { type: 'easeInOut', duration: 0.5 },
};

export enum NotificationStatus {
  Info,
  Success,
  Error,
}

export function useNotificationSection() {
  const [notifications, setNotifications] = useState<
    {
      id: number;
      timeoutId: ReturnType<typeof setTimeout> | undefined;
      notification: { status: NotificationStatus; message: string };
    }[]
  >([]);
  const idCounter = useRef(1);
  const getId = () => idCounter.current++;
  const dismissAllNotifications = () => setNotifications([]);
  const dismissNotification = (id: number) =>
    setNotifications((notifications) => {
      return notifications.filter(({ id: nId, timeoutId }) => {
        const isNotificationToDismiss = id === nId;
        if (isNotificationToDismiss && timeoutId) {
          clearTimeout(timeoutId);
        }
        return !isNotificationToDismiss;
      });
    });
  useEffect(() => {
    return () => {
      for (const n of notifications) {
        if (n.timeoutId) {
          clearTimeout(n.timeoutId);
        }
      }
    };
  }, []);
  const displayNotification = (
    status: NotificationStatus,
    message: string,
    durationInMs = 2500,
  ) => {
    const id = getId();
    const notification = {
      id,
      timeoutId:
        durationInMs === Infinity
          ? undefined
          : setTimeout(() => dismissNotification(id), durationInMs),
      notification: {
        status,
        message,
      },
    };
    setNotifications((notifications) => [...notifications, notification]);
  };
  return {
    notifications,
    dismissAllNotifications,
    dismissNotification,
    displayNotification,
  };
}

type UseNotificationSection = typeof useNotificationSection;

export function NotificationSection({
  notifications,
  onDismissNotification,
  ...props
}: {
  notifications: ReturnType<UseNotificationSection>['notifications'];
  onDismissNotification: ReturnType<UseNotificationSection>['dismissNotification'];
} & BoxProps) {
  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      {notifications.length > 0 && (
        <MotionFlex
          zIndex={1000}
          flexDirection="column"
          gridGap="1rem"
          position="fixed"
          top="6rem"
          right="1rem"
        >
          {notifications.map(({ id, notification }) => {
            return (
              <MotionFlex
                layout
                transition={{ type: 'spring' }}
                key={`notifcation${id}`}
                marginLeft="1rem"
                as="button"
                border="none"
                bg="neutrals0"
                borderRadius="roundedSquare"
                boxShadow="0px 2.53882px 7.61645px rgba(0, 0, 0, 0.18)"
                onClick={() => onDismissNotification(id)}
                flexDirection="row"
                gridGap="0.5rem"
                variants={variants}
                transitions={transitions}
                initial="hidden"
                exit="hidden"
                animate="visible"
                alignItems="center"
                _hover={{ bg: 'neutrals30', opacity: 0.6, cursor: 'pointer' }}
                paddingX="1rem"
                paddingY="0.75rem"
                {...props}
              >
                {notification.status === NotificationStatus.Success && (
                  <CheckmarkIcon size="1.25rem" color="#007F5E" />
                )}
                {notification.status === NotificationStatus.Info && <InfoIcon size="1.25rem" />}
                {notification.status === NotificationStatus.Error && <CloseIcon size="1.25rem" />}
                <Text
                  fontSize="s1"
                  fontWeight="semibold"
                  {...(notification.status === NotificationStatus.Error && {
                    fontSize: 's0',
                    lineHeight: 's00',
                  })}
                  maxW={300}
                  textAlign="left"
                >
                  {notification.message}
                </Text>
              </MotionFlex>
            );
          })}
        </MotionFlex>
      )}
    </AnimatePresence>
  );
}

export const Context = createContext({} as ReturnType<UseNotificationSection>);

export const useNotificationSectionContext = () => useContext(Context);

export function NotificationSectionProvider({ children }: { children: ReactNode }) {
  const notificationSection = useNotificationSection();

  return (
    <Context.Provider value={notificationSection}>
      <NotificationSection
        notifications={notificationSection.notifications}
        onDismissNotification={notificationSection.dismissNotification}
      />
      {children}
    </Context.Provider>
  );
}

export const NotificationSectionConsumer = Context.Consumer;
