/* storybook-check-ignore */
import { MouseEvent, ReactNode } from 'react';
import Modal from 'react-modal';

import styled from '@emotion/styled';
import { Button, Flex, Text } from '@opendoor/bricks/core';
import { ThemedLite } from '@opendoor/bricks/theme/ODThemeLite';
import { motion } from 'framer-motion';

import useDisabledBodyScroll from './hooks/useDisabledBodyScroll';
import CloseIcon from './icons/CloseIcon';
import InfoIcon from './icons/InfoIcon';

type Props = {
  title?: ReactNode;
  children: ReactNode;
  icon?: ReactNode;
  isOpen: boolean;
  onRequestClose?: () => void;
};

export default function InfoModal({ title, children, isOpen, icon, onRequestClose }: Props) {
  useDisabledBodyScroll(isOpen);
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          display: 'flex',
          backgroundColor: 'rgba(34, 34, 34, 0.33)',
          zIndex: 999,
          width: '100vw',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2rem',
        },
        content: {
          padding: 0,
          display: 'flex',
          flexShrink: 1,
          flexGrow: 0,
          backgroundColor: 'transparent',
          border: 'none',
          position: 'relative',
          inset: 0,
          overflow: 'visible',
        },
      }}
    >
      <ThemedLite theme="exclusives">
        <PopupContainer
          onClick={(ev: MouseEvent<HTMLDivElement>) => {
            ev.preventDefault();
            ev.stopPropagation();
          }}
          animate={{ scale: 1, opacity: 1 }}
          initial={{ scale: 0.5, opacity: 0 }}
          maxWidth="none !important"
        >
          <Flex flexDirection="column" gridGap="1rem">
            <Flex flexDirection="row" justifyContent="space-between">
              {icon || <InfoIcon size={33} />}
              <Button
                variant="icon"
                aria-label="Close"
                analyticsName=""
                width={33}
                height={33}
                onClick={onRequestClose}
              >
                <CloseIcon />
              </Button>
            </Flex>
            {title && (
              <Text
                mt="0.5rem"
                fontSize="s2"
                lineHeight="s1"
                color="neutrals100"
                fontWeight="semibold"
              >
                {title}
              </Text>
            )}
            <Text>{children}</Text>
          </Flex>
        </PopupContainer>
      </ThemedLite>
    </Modal>
  );
}

const PopupContainer = styled(motion(Flex))`
  border-radius: 1rem;
  padding: 1.5rem 1.625rem;
  background-color: var(--colors-neutrals0);
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  height: fit-content;
  position: relative;
  z-index: 10;
`;
