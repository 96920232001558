/* storybook-check-ignore */
import { useCallback, useEffect, useRef, useState } from 'react';

import { useDevice } from '@opendoor/bricks/hooks/useMedia';

/**
 * Helper hook to remove scrolling to the parent element when modal is opened.
 */
const useDisabledBodyScroll = (isOpen: boolean) => {
  const { isMobile } = useDevice();
  const [isScrollingBodyPrevented, setIsScrollingBodyPrevented] = useState(false);
  const bodyStyleTop = useRef<number | null>(null);
  /*
      Scrolling needs to be blocked on elements behind the modal for a better accessable design.
      The useEffect below blocks scrolling when modal is opened.
      https://chakra-ui.com/docs/overlay/modal#keyboard-and-focus-management
  */
  const handleModalClose = useCallback(() => {
    if (isMobile) {
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.overflow = '';
      if (bodyStyleTop.current) {
        window.scrollTo(0, bodyStyleTop.current);
      }
      setIsScrollingBodyPrevented(false);
    } else {
      document.body.style.overflow = '';
      setIsScrollingBodyPrevented(false);
    }
  }, [isMobile]);
  useEffect(() => {
    // Need specific styling for iOS body touchscreen
    // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
    if (!isOpen && !isScrollingBodyPrevented) {
      // does not run rest of code in the useEffect since modal has not been opened & is not in the process of being opened
      // this is needed for pages that have multiple modals on the page
      return;
    }

    if (isOpen) {
      if (isMobile) {
        bodyStyleTop.current = Number(document.body.style.top || '0');
        document.body.style.position = 'static';
        document.body.style.overflow = 'hidden';
        setIsScrollingBodyPrevented(true);
      } else {
        document.body.style.overflow = 'hidden';
        setIsScrollingBodyPrevented(true);
      }
    } else {
      handleModalClose();
    }

    // Ensure that we re-enable scrolling if the component is unmounted
    return handleModalClose;
  }, [isMobile, isOpen, isScrollingBodyPrevented, handleModalClose]);
};

export default useDisabledBodyScroll;
