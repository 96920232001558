/* storybook-check-ignore */
import { FunctionComponent } from 'react';

const CloseIcon: FunctionComponent<{ size?: string | number; color?: string }> = ({
  size = '1rem',
  color = '#C34143',
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.88505 8.24442C10.0383 8.10819 10.0383 7.88732 9.88505 7.75108L7.66502 5.77772L9.88505 3.80436C10.0383 3.66813 10.0383 3.44726 9.88505 3.31102L8.77504 2.32434C8.62178 2.18811 8.37329 2.18811 8.22003 2.32434L6 4.2977L3.77997 2.32434C3.62671 2.18811 3.37822 2.18811 3.22496 2.32434L2.11495 3.31102C1.96168 3.44725 1.96168 3.66813 2.11495 3.80436L4.33498 5.77772L2.11495 7.75108C1.96169 7.88732 1.96169 8.10819 2.11495 8.24442L3.22496 9.2311C3.37822 9.36734 3.62671 9.36734 3.77997 9.2311L6 7.25774L8.22003 9.2311C8.37329 9.36734 8.62177 9.36734 8.77504 9.2311L9.88505 8.24442Z"
      fill={color}
    />
  </svg>
);

export default CloseIcon;
