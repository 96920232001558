/* storybook-check-ignore */
import { FunctionComponent } from 'react';

const CheckmarkIcon: FunctionComponent<{ size?: string; color?: string }> = ({
  size = '1rem',
  color = '#092EE6',
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.82703 3.13088C8.65252 2.95637 8.36959 2.95637 8.19509 3.13088L5.16298 6.16298L3.80491 4.80491C3.63041 4.63041 3.34748 4.63041 3.17297 4.80491L2.13088 5.84701C1.95637 6.02151 1.95637 6.30444 2.13088 6.47895L3.80492 8.15299L4.84701 9.19508C4.84701 9.19508 4.84701 9.19508 4.84701 9.19508C4.93158 9.27964 5.0416 9.32323 5.15241 9.32583C5.27026 9.32861 5.38901 9.28502 5.47896 9.19508L6.52104 8.15298L9.86912 4.80492C10.0436 4.63041 10.0436 4.34748 9.86912 4.17297L8.82703 3.13088Z"
      fill={color}
    />
  </svg>
);

export default CheckmarkIcon;
