/* storybook-check-ignore */
export default function InfoIcon({
  color = '#525252',
  size = 16,
}: {
  color?: string;
  size?: string | number;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM7 5.5C7 6.05228 7.44772 6.5 8 6.5C8.55228 6.5 9 6.05228 9 5.5C9 4.94772 8.55228 4.5 8 4.5C7.44772 4.5 7 4.94772 7 5.5ZM7.66667 7.16667C7.29848 7.16667 7 7.46514 7 7.83333V10.5C7 10.8682 7.29848 11.1667 7.66667 11.1667H8.33333C8.70152 11.1667 9 10.8682 9 10.5V7.83333C9 7.46514 8.70152 7.16667 8.33333 7.16667H7.66667Z"
        fill={color}
      />
    </svg>
  );
}
