/* storybook-check-ignore */
import { useMemo, useState } from 'react';

import { css } from '@emotion/react';
import { Button, ButtonProps } from '@opendoor/bricks/core';

import {
  useWatchlistContext,
  WatchlistContext,
} from 'components/exclusives/contexts/WatchlistContextProvider';

import { ListingWithComputedProperties } from '../../declarations/exclusives/listing';
import {
  getIsInContract,
  getIsOOMLS,
  getIsSellerOfferExperiment,
  getListingPriceCents,
  getListingStreet,
} from '../../helpers/exclusives/listing';
import { useObservability } from '../../helpers/observability';
import AccountConnector, { AccountProps } from '../shared/AccountConnector';
import { ListingStates } from './constants/constants';
import useExclusiveAnalytics from './hooks/useExclusiveAnalytics';
import useWatchlist, { AddedToWatchlistSource } from './hooks/useWatchlist';
import WatchlistModal, { getAnalyticsLabel } from './WatchlistModal';

interface Props {
  listing: ListingWithComputedProperties;
  isVisible?: boolean;
  isPrimary?: boolean;
  doneWhenAddedToWatchlist?: boolean;
  doneWhenAddedToWatchlistText?: string;
  source?: 'gallery' | 'pdp' | 'self-unlock';
  buttonProps?: Omit<ButtonProps, 'analyticsName' | 'aria-label' | 'onClick'>;
  userEmail?: string;
}

const disabledActionButtonStyle = {
  cursor: 'not-allowed',
  backgroundColor: '#1D4CE4',
  color: 'var(--colors-neutrals0)',
  opacity: 0.8,
  border: 0,
};

export function getWatchlistButtonDisplayText(
  listing: ListingWithComputedProperties,
  isSubscribed: boolean | undefined,
) {
  const priceCents = getListingPriceCents(listing);
  const hasOpenHouse = !!listing.exclusive_listing_info.ongoing_and_upcoming_open_houses?.length;
  const isSellerOfferExperiment = getIsSellerOfferExperiment(listing);
  const isListed = listing.exclusive_listing_info.state === 'el_listed';
  const isSetup = listing.exclusive_listing_info.state === 'el_setup';
  const isInContract = getIsInContract(listing);
  const isOOMLS = getIsOOMLS(listing);

  if (isSubscribed) {
    if (isSellerOfferExperiment) {
      if (hasOpenHouse && (isListed || isSetup)) {
        return 'Reminder set';
      } else if (!hasOpenHouse && isSetup) {
        return 'Notification set';
      }
    }
    if (!listing.listing_price_cents) {
      return 'Pricing Requested';
    }

    return 'Added to watchlist!';
  }

  if (isOOMLS || isInContract) {
    return 'Join waitlist';
  }

  if (isSellerOfferExperiment) {
    if (hasOpenHouse && (isListed || isSetup)) {
      return 'Get open house email updates';
    } else if (!hasOpenHouse && (isListed || isSetup || isInContract)) {
      return 'Get email updates';
    }
  }

  if (!priceCents || priceCents === 0) {
    return 'Request Pricing';
  }

  return 'Add to Watchlist';
}

const WatchlistButton = ({
  isVisible,
  isPrimary,
  listing,
  buttonProps,
  source = 'pdp',
  doneWhenAddedToWatchlist,
  userEmail,
  ...account
}: Props & AccountProps) => {
  const { isOnWatchlist, setIsOnWatchlist, addToWatchlist, isLoading, toggleWatchlist, error } =
    useWatchlist(listing);
  const buttonDisplayText = useMemo(
    () => getWatchlistButtonDisplayText(listing, isOnWatchlist),
    [listing.listing_price_cents, isOnWatchlist],
  );
  if (!isVisible) return null;
  return (
    <WatchlistContext.Provider
      value={{
        toggleWatchlist,
        isOnWatchlist,
        setIsOnWatchlist,
        addToWatchlist,
        isLoading,
        listing,
        buttonDisplayText,
        source: `${source}_get_email_updates` as AddedToWatchlistSource &
          `${string}_get_email_updates`,
        error,
      }}
    >
      <WatchlistButtonWithoutContextProvider
        account={account}
        listing={listing}
        isPrimary={isPrimary}
        buttonProps={{ ...buttonProps, ...(account.infoIsLoading ? { disabled: true } : null) }}
        doneWhenAddedToWatchlist={doneWhenAddedToWatchlist}
        userEmail={userEmail}
      />
    </WatchlistContext.Provider>
  );
};

export function WatchlistButtonWithoutContextProvider({
  listing,
  isPrimary,
  buttonProps,
  account,
  doneWhenAddedToWatchlist,
  doneWhenAddedToWatchlistText = 'Done!',
  source: sourceParam,
  userEmail: userEmailParam,
}: Omit<Props, 'isVisible'> & { account?: AccountProps }) {
  const { trackEvent } = useObservability();
  const { trackExclusivesEvent } = useExclusiveAnalytics();
  const [watchlistModalOpen, setWatchlistModalOpen] = useState(false);
  const userEmail: string | undefined = account?.loggedIn
    ? account.email
      ? account.email
      : account.human?.get('email')
    : userEmailParam;
  const {
    isLoading,
    setIsOnWatchlist,
    addToWatchlist,
    buttonDisplayText,
    source: sourceState,
    isOnWatchlist,
  } = useWatchlistContext();
  const source: AddedToWatchlistSource | undefined = sourceParam
    ? (`${sourceParam}_get_email_updates` as AddedToWatchlistSource & `${string}_get_email_updates`)
    : sourceState;
  const analyticsLabel = getAnalyticsLabel(buttonDisplayText);
  const analyticsAdditionalContextualData = {
    button_type: analyticsLabel,
    source,
    action_type: 'add',
  };
  const handleWatchlistModalState = (state: boolean) => () => {
    if (state) {
      // TODO: remove categoryOverride
      // observability is initialized with 'cosmos' as the category for all events
      trackEvent(
        'cta-click',
        'add-watchlist',
        {
          listing_id: listing.id,
          slug: listing.slug,
        },
        { categoryOverride: 'exclusive-listings' },
      );
    }

    if (userEmail) {
      addToWatchlist(userEmail, { source }).finally(() => {
        // NOTE: We always add watchlists from this path, we don't have a 'remove' option
        trackExclusivesEvent('exclusives-favorite', 'add', listing, {
          userEmail,
          ...analyticsAdditionalContextualData,
        });
      });
    } else {
      setWatchlistModalOpen(state);
    }
  };

  const getEmailUpdatesText = useMemo(() => {
    const isOOMLS = getIsOOMLS(listing);
    return isOOMLS || listing.el_state === ListingStates.EL_IN_CONTRACT
      ? 'Join waitlist'
      : 'Get email updates';
  }, [listing]);

  const getWaitlistText = useMemo(() => {
    const isOOMLS = getIsOOMLS(listing);
    return isOOMLS || listing.el_state === ListingStates.EL_IN_CONTRACT
      ? doneWhenAddedToWatchlistText
      : 'Subscribed to email updates';
  }, [doneWhenAddedToWatchlistText]);

  return (
    <>
      <Button
        px="0.5rem"
        variant={isPrimary ? 'primary' : 'secondary'}
        aria-label="Get email updates"
        _disabled={disabledActionButtonStyle}
        disabled={isLoading || (doneWhenAddedToWatchlist && isOnWatchlist)}
        loadingText={getEmailUpdatesText}
        onClick={handleWatchlistModalState(true)}
        loading={isLoading}
        analyticsName="cosmos-exclusives-add-to-watchlist"
        gridGap="0.5rem"
        // Overriding button spinner styling
        css={css`
          div {
            position: relative;
            margin-top: unset;
            margin-left: unset;
            top: unset;
            left: unset;
          }
        `}
        {...buttonProps}
      >
        {doneWhenAddedToWatchlist && isOnWatchlist ? getWaitlistText : getEmailUpdatesText}
      </Button>
      <WatchlistModal
        listing={listing}
        isOpen={watchlistModalOpen}
        setIsOnWatchlist={setIsOnWatchlist}
        streetName={getListingStreet(listing)}
        onRequestClose={handleWatchlistModalState(false)}
        _analyticsLabel="add"
        analyticsAdditionalContextualData={analyticsAdditionalContextualData}
        source={sourceParam}
      />
    </>
  );
}
export default AccountConnector(WatchlistButton);
